.div,
.icon {
  position: relative;
}
.icon {
  height: 18px;
  width: 18px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 18px;
}
.div {
  align-self: stretch;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  font-family: var(--body);
  color: var(--text-00);
  text-align: left;
  white-space: nowrap;
}
.btn,
.wrapper {
  display: flex;
  align-items: flex-start;
}
.wrapper {
  width: 125px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}
.btn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-xl);
  background-color: var(--color-gold);
  align-self: stretch;
  border-radius: var(--br-xs);
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-3xs);
}
.appleLogoIcon {
  height: 18px;
  width: 18px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.appleId {
  align-self: stretch;
  position: relative;
  font-size: var(--body-size);
  line-height: 100%;
  font-weight: 500;
  font-family: var(--body);
  color: var(--text-ef);
  text-align: left;
  white-space: nowrap;
}
.appleIdWrapper {
  height: 14px;
  width: 118px;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-11xs) 0 0;
  box-sizing: border-box;
}
.appleIdWrapper,
.btn1,
.btnParent,
.frameWrapper {
  display: flex;
  align-items: flex-start;
}
.btn1 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-68xl);
  background-color: var(--text-00);
  align-self: stretch;
  border-radius: var(--br-xs);
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-3xs);
}
.btnParent,
.frameWrapper {
  justify-content: flex-start;
  max-width: 100%;
}
.btnParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-5xs);
}
.frameWrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
}
.homeIndicator {
  height: 5px;
  width: 134px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--text-00);
}
.frameParent,
.systemLightHomeIndicato {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}
.systemLightHomeIndicato {
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xl) var(--padding-5xs);
}
.frameParent {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
}
section {
  padding-bottom: 5%;
}
