.unionIcon {
  align-self: stretch;
  height: 47.3px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.unionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-135xl-4) 0 0;
}
.frameParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px 0 0;
  gap: var(--gap-5xl-2);
  text-align: center;
  font-size: var(--font-size-sm-3);
  color: var(--text-00);
  font-family: var(--font-sf-pro-text);
}

.div {
  display: flex;
  align-items: flex-start;
}

.div {
  width: 100%;
  position: relative;
  background-color: var(--light-mode-pw);
  /* background-color: aquamarine; */
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-2xs-6) var(--padding-base) 53px 19px;
  box-sizing: border-box;
  gap: 520.7px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--label-b-size);
  color: var(--text-00);
  font-family: var(--body);
}
