.iconChild {
  height: 16px;
  width: 10px;
  position: relative;
}
.icon {
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
  height: 36px;
}
.b {
  align-self: center; /* Aligns the element in the center vertically */
  height: auto; /* Allows the height to adjust based on content */
  position: relative; /* Keeps the element positioned relative to its normal position */
  line-height: 1; /* Adjust for better text alignment */
  display: block; /* Better cooperation with flexbox layout */
  opacity: 1; /* Set to 1 to see the element, then adjust as needed */
}
.title,
.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.wrapper {
  width: 56px;
  flex-direction: column;
  padding: var(--padding-3xs) 0 0;
  box-sizing: border-box;
}
.title {
  margin-top: 2vh;
  margin-bottom: 5vh;
  align-self: stretch;
  /* overflow: hidden; */
  flex-direction: row;
  /* padding: var(--padding-6xs) var(--padding-base); */
  gap: var(--gap-81xl);
  text-align: center;
  font-size: var(--subtitle-size);
  color: var(--text-00);
  font-family: var(--body);
}

.transparentButton {
  background: none; /* Remove button background */
  border: none; /* Remove button border */
  padding: 0; /* Remove button padding */
  cursor: pointer; /* Change cursor to pointer */
  display: inline-flex; /* Ensure the button size adjusts to its content */
  align-items: center; /* Center align the content vertically */
  justify-content: center; /* Center align the content horizontally */
}

.transparentButton img {
  display: block; /* Prevents unwanted spacing around the image */
}
