.vectorIcon {
  width: 381px;
  position: relative;
  max-height: 100%;
  display: none;
  max-width: 100%;
}
.div1 {
  flex: 1;
  position: relative;
  letter-spacing: -0.44px;
  line-height: 17.73px;
  font-weight: 600;
}
.statusbarTime,
.statusbarTimeWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}
.statusbarTime {
  align-self: stretch;
  height: 17.7px;
  border-radius: 21.28px;
  flex-direction: row;
  padding: var(--padding-12xs-9) 0 0;
}
.statusbarTimeWrapper {
  width: 56.5px;
  flex-direction: column;
  padding: 0 var(--padding-5xs) 0 0;
}
.unionIcon {
  align-self: stretch;
  height: 47.3px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}
.unionContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-135xl-4) 0 0;
}
.wifiIcon {
  position: absolute;
  top: 0;
  left: 19.6px;
  width: 13.5px;
  height: 9.7px;
}
.mobileSignalIcon {
  position: absolute;
  top: 0.3px;
  left: 0;
  width: 15.1px;
  height: 9.5px;
}
.networkStatus {
  height: 9.8px;
  width: 33.1px;
  position: relative;
}
.batteryIcon {
  align-self: stretch;
  width: 21.6px;
  position: relative;
  max-height: 100%;
  min-height: 10px;
}
.networkStatusParent {
  flex-direction: row;
  gap: var(--gap-8xs-5);
}
.frameParent,
.frameWrapper,
.networkStatusParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper {
  flex-direction: column;
  padding: var(--padding-9xs-6) 0 0;
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px 0 0;
  gap: var(--gap-5xl-2);
  text-align: center;
  font-size: var(--font-size-sm-3);
  color: var(--text-00);
  font-family: var(--font-sf-pro-text);
}
.easybrainSoft {
  position: relative;
  line-height: 16px;
  font-weight: 600;
  display: inline-block;
  min-width: 82px;
  background: linear-gradient(
    90deg,
    #000000,
    #c0c0c0,
    #000000
  ); /* Black to Silver gradient */
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 3s linear infinite;
}
.div,
.easybrainSoftWrapper {
  display: flex;
  align-items: flex-start;
}
.easybrainSoftWrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 27px 0 var(--padding-xl);
}
.div {
  width: 100%;
  position: relative;
  background-color: var(--light-mode-pw);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-2xs-6) var(--padding-base) 53px 19px;
  box-sizing: border-box;
  gap: 60vh;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: var(--label-b-size);
  color: var(--text-00);
  font-family: var(--body);
  min-height: 100vh; /* Full height of the viewport */
}
@keyframes shine {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
}
