.btn,
.div {
  white-space: nowrap;
}
.div {
  width: 101px;
  position: relative;
  line-height: 100%;
  font-weight: 500;
  display: inline-block;
}
.btn {
  align-self: stretch;
  border-radius: var(--br-xs);
  border: 1px solid var(--light-mode-main);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-lg) var(--padding-xl);
  text-align: left;
  font-size: var(--body-size);
  color: var(--light-mode-main);
  font-family: var(--body);
}
