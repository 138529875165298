.b,
.input {
  position: relative;
  line-height: 100%;
  display: inline-block;
}
.b {
  min-width: 64px;
  white-space: nowrap;
}
.input {
  width: 140px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: var(--body);
  font-size: var(--body-size);
  background-color: transparent;
  height: 14px;
  color: var(--text-ca);
  text-align: left;
  padding: 0;
}
.field1 {
  flex: 1;
  border-radius: var(--br-xs);
  border: 1px solid var(--text-ef);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-sm);
  width: 50%;
}
.b1 {
  position: relative;
  font-size: var(--label-b-size);
  line-height: 100%;
  display: inline-block;
  font-family: var(--body);
  color: var(--light-mode-pw);
  text-align: center;
  min-width: 65px;
}
.span {
  color: var(--color-tomato);
}
.field1:focus {
  box-shadow: 0 0 0 2px var(--light-mode-main); /* Orange highlight */
  outline: none; /* Ensure the default outline is removed */
}
.field,
.field2,
.textField {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.field2 {
  cursor: pointer;
  border: 0;
  padding: var(--padding-lg) var(--padding-mid);
  background-color: var(--light-mode-main);
  border-radius: var(--br-xs);
  justify-content: center;
  white-space: nowrap;
}
.field,
.textField {
  align-self: stretch;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.textField {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--body-size);
  color: var(--text-00);
}
