html, body {
  background-color: var(--background-color);
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define the @font-face rules for each font */

@font-face {
  font-family: "기본";
  src: url("../public/1pre.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "나눔손글씨 무궁화";
  src: url("../public/NanumMuGungHwa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "나눔손글씨 금은보화";
  src: url("../public/NanumGeumEunBoHwa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "나눔손글씨 또박또박";
  src: url("../public/NanumDdoBagDdoBag.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Set default CSS variable for the sans font */
:root {
  --font-sans-family: "기본", sans-serif; /* Default to Pretendard or sans-serif */
}

/* Apply the font-sans with higher specificity */
.font-sans {
  font-family: var(--font-sans-family) !important;
}
