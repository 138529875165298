.div {
  position: relative;
  line-height: 100%;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}
.field {
  align-self: stretch;
  border-radius: var(--br-xs);
  border: 1px solid var(--text-ef);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-sm);
  text-align: left;
  font-size: var(--body-size);
  color: var(--text-ca);
  font-family: var(--body);
  width: 100%;
}
.b {
  position: relative;
  line-height: 100%;
  display: inline-block;
  min-width: 88px;
  white-space: nowrap;
}
.textField {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
/* .errorWrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.alertIcon {
  margin-right: 5px;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
} */
.fieldWithError {
  border-color: red; /* Optional: Change border color if there's an error */
}

.errorIcon {
  position: absolute;
  top: 50%;
  left: 10px; /* Position the icon inside the field */
  transform: translateY(-50%);
  pointer-events: none; /* Make sure the icon doesn't interfere with input */
}

.alertIcon {
  color: red; /* Optional: Set color for the alert icon */
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
  margin-top: 5px;
}
