.textFieldParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.textFieldParent {
  margin: 0;
  flex: 1;
  gap: var(--gap-xl);
  max-width: 100%;
}
.frameDiv {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.frameDiv {
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
}
