.div {
  position: relative;
  line-height: 100%;
  font-weight: 500;
  display: inline-block;
  min-width: 64px;
}
.findIdButton,
.loginButton {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-3xl) 0 var(--padding-xl);
}
.loginButton {
  justify-content: flex-start;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  max-width: 100%;
}
.homeIndicator {
  height: 5px;
  width: 134px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--text-00);
}
.bottomButtons,
.systemLightHomeIndicato {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  margin-top: 40vh;
}
.systemLightHomeIndicato {
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xl) var(--padding-5xs);
}
.bottomButtons {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-mini);
  max-width: 100%;
  text-align: left;
  font-size: var(--body-size);
  color: var(--text-00);
  font-family: var(--body);
}
