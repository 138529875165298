.vectorIcon {
  width: 381px;
  position: relative;
  max-height: 100%;
  display: none;
  max-width: 100%;
}
.pwInner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-base) 334px;
  box-sizing: border-box;
  max-width: 100%;
}
.btn1 {
  position: relative;
  font-size: var(--body-size);
  line-height: 100%;
  display: inline-block;
  font-family: var(--body);
  color: var(--light-mode-pw);
  text-align: left;
  min-width: 37px;
}
.btn,
.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.btn {
  cursor: pointer;
  border: 0;
  padding: var(--padding-xl);
  background-color: transparent;
  flex: 1;
  border-radius: var(--br-xs);
  background: linear-gradient(64.95deg, #61e3eb, #3677f6);
  justify-content: center;
}
.btnWrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 var(--padding-base);
}
.homeIndicator {
  height: 5px;
  width: 134px;
  position: relative;
  border-radius: var(--br-81xl);
  background-color: var(--text-00);
}
.pw,
.systemLightHomeIndicato {
  display: flex;
  align-items: flex-start;
}
.systemLightHomeIndicato {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: var(--padding-6xs) var(--padding-xl) var(--padding-5xs);
}
.pw {
  width: 100%;
  position: relative;
  background-color: var(--light-mode-pw);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-2xs-6) 0 0;
  box-sizing: border-box;
  gap: var(--gap-base);
  line-height: normal;
  letter-spacing: normal;
}

.textFieldParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.field {
  align-self: stretch;
  border-radius: var(--br-xs);
  border: 1px solid var(--text-ef);
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-sm);
}
.textFieldParent {
  flex: 1;
  flex-direction: column;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--body-size);
  color: var(--text-00);
  font-family: var(--title1);
}
