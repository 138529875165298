.vectorIcon {
  width: 381px;
  position: relative;
  max-height: 100%;
  display: none;
  max-width: 100%;
}
.div {
  width: 100%;
  position: relative;
  background-color: var(--light-mode-pw);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-2xs-6) 0 0;
  box-sizing: border-box;
  gap: 40vh;
  line-height: normal;
  letter-spacing: normal;
}
