.frameParent {
  align-self: stretch;
  display: flex;
  max-width: 100%;
}
.frameParent {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-smi-9);
  text-align: center;
  font-size: var(--font-size-sm-3);
  color: var(--text-00);
  font-family: var(--font-sf-pro-text);
}
