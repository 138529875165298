.div {
  flex: 1;
  position: relative;
  letter-spacing: -0.44px;
  line-height: 17.73px;
  font-weight: 600;
  flex-shrink: 0;
}
.statusbarTime {
  height: 17.7px;
  width: 47.9px;
  border-radius: 21.28px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs-9) 0 0;
  box-sizing: border-box;
  flex-shrink: 0;
}
.wifiIcon {
  position: absolute;
  top: 0;
  left: 19.6px;
  width: 13.5px;
  height: 9.7px;
}
.mobileSignalIcon {
  position: absolute;
  top: 0.3px;
  left: 0;
  width: 15.1px;
  height: 9.5px;
}
.wifiParent {
  height: 9.8px;
  width: 33.1px;
  position: relative;
}
.batteryIcon {
  align-self: stretch;
  width: 21.6px;
  position: relative;
  max-height: 100%;
  min-height: 10px;
}
.frameContainer,
.frameGroup {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameGroup {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-8xs-5);
  flex-shrink: 0;
}
.frameContainer {
  width: 59.2px;
  flex-direction: column;
  padding: var(--padding-9xs-6) 0 0;
  box-sizing: border-box;
}
.frameWrapper,
.statusbarTimeParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}
.statusbarTimeParent {
  flex: 1;
  justify-content: space-between;
  gap: var(--gap-xl);
}
.frameWrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 var(--padding-base) 0 var(--padding-xl);
  box-sizing: border-box;
}
.b {
  position: relative;
  line-height: 100%;
  display: inline-block;
  min-width: 37px;
}
.textField {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.frameDiv,
.frameParent,
.textFieldWrapper {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.textFieldWrapper {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}
.frameDiv,
.frameParent {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 var(--padding-base);
  box-sizing: border-box;
  text-align: left;
  font-size: var(--body-size);
  font-family: var(--title1);
}
.frameParent {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 0 var(--padding-9xs);
  gap: var(--gap-smi-9);
  text-align: center;
  font-size: var(--font-size-sm-3);
  color: var(--text-00);
  font-family: var(--font-sf-pro-text);
}
