.pw {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pw {
  width: 100%;
  position: relative;
  background-color: var(--light-mode-pw);
  overflow: hidden;
  /* padding: var(--padding-2xs-6) 0 0; */
  box-sizing: border-box;
  /* gap: var(--gap-367xl); */
  /* gap: 40vh; */
  line-height: normal;
  letter-spacing: normal;
}
