.b,
.input {
  font-size: var(--body-size);
  position: relative;
  line-height: 100%;
  text-align: left;
  display: inline-block;
}
.b {
  font-family: var(--title1);
  color: var(--text-00);
  min-width: 28px;
}
.field:focus {
  box-shadow: 0 0 0 2px var(--light-mode-main); /* Orange highlight */
  outline: none; /* Ensure the default outline is removed */
}
.input {
  width: 101px;
  border: 0;
  outline: 0;
  font-weight: 500;
  font-family: var(--body);
  background-color: transparent;
  height: 14px;
  color: var(--text-ca);
  padding: 0;
}
.field,
.textField {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.field {
  border-radius: var(--br-xs);
  border: 1px solid var(--text-ef);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-mini) var(--padding-sm);
}
.textField {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-3xs);
}
.span {
  color: var(--color-tomato);
}
.b {
  position: relative;
  line-height: 100%;
}
