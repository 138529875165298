/* Define the @font-face rules for each font */
@font-face {
  font-family: "나눔손글씨 무궁화";
  src: url("../../public/NanumMuGungHwa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "나눔손글씨 금은보화";
  src: url("../../public/NanumGeumEunBoHwa.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "나눔손글씨 또박또박";
  src: url("../../public/NanumDdoBagDdoBag.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --body: "Pretendard";
  --font-sf-pro-text: "SF Pro Text";
  --title1: "Pretendard Variable";
  --font-sans-family: sans-serif, "나눔손글씨 무궁화", "나눔손글씨 금은보화",
    "나눔손글씨 또박또박";

  /* font sizes */
  --body-size: 14px;
  --font-size-sm-3: 13.3px;
  --label-b-size: 12px;
  --title-size: 18px;
  --subtitle-size: 16px;

  /* Colors */
  --light-mode-pw: #fff;
  --text-00: #000;
  --light-mode-main: #3677f6;
  --text-ef: #e9ecef;
  --color-gold: #fee500;
  /* --text-ca: #a9b6ca; */
  --text-ca: #000;
  --color-tomato: #e5592d;

  /* Gaps */
  --gap-xl: 20px;
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-5xl-2: 24.2px;
  --gap-8xs-5: 4.5px;
  --gap-13xl: 32px;
  --gap-mini: 15px;
  --gap-base: 16px;
  --gap-sm-7: 13.7px;
  --gap-81xl: 100px;
  --gap-smi-9: 12.9px;
  --gap-59xl: 78px;
  --gap-367xl: 386px;
  --gap-xs: 12px;
  --gap-73xl: 92px;

  /* Paddings */
  --padding-2xs-6: 10.6px;
  --padding-6xs: 7px;
  --padding-xl: 20px;
  --padding-5xs: 8px;
  --padding-base: 16px;
  --padding-lg: 18px;
  --padding-68xl: 87px;
  --padding-11xs: 2px;
  --padding-9xs-6: 3.6px;
  --padding-135xl-4: 154.4px;
  --padding-12xs-9: 0.9px;
  --padding-3xl: 22px;
  --padding-mini: 15px;
  --padding-sm: 14px;
  --padding-3xs: 10px;
  --padding-9xs: 4px;
  --padding-mid: 17px;
  --padding-20xl: 39px;

  /* Border radiuses */
  --br-81xl: 100px;
  --br-xs: 12px;
  --br-2xl-3: 21.3px;
}
